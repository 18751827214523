import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export default function GoldSponsors() {
  const data = useStaticQuery(graphql`
    query goldQuery {
      markdownRemark {
        frontmatter {
          gold {
            title
            url
            image {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, height: 175)
              }
            }
          }
        }
      }
    }
  `);

  const { gold } = data.markdownRemark.frontmatter;
  console.log(gold);
  return (
    <div className="bg-darkGray bg-opacity-25">
      <div className="mx-auto  py-16 px-4 sm:py-10 sm:px-6  lg:px-8">
        <div className="lg:grid lg:grid-cols-1 lg:gap-8">
          <h2 className="sponsor-title">Gold Sponsors</h2>
          <div className="mt-8 flow-root self-center lg:mt-0">
            <div className="-mt-4 -ml-8 flex flex-wrap justify-around lg:-ml-4">
              {gold.map((post) => (
                <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow justify-center lg:ml-4 lg:flex-grow-0">
                  <a href={post.url} alt={post.title}>
                    <GatsbyImage
                      image={post.image.childImageSharp.gatsbyImageData}
                      alt={post.title}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';

export default function Camping() {
  return (
    <div className="bg-white">
      <div className="px-6 py-24 sm:px-6 sm:py-16 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="font-heading text-3xl font-bold uppercase tracking-wide text-gray-900 sm:text-5xl">
            Camping Available
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            Camping is available. Please reach out to Bob for more information.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="tel:519-495-0503"
              className="rounded-md bg-primary px-5 py-2.5 text-xl font-semibold text-white shadow-sm hover:bg-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              (519)-495-0503
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { PhoneIcon } from '@heroicons/react/24/solid';
import { StaticImage } from 'gatsby-plugin-image';

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
];

export default function InfoSection() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-lightGray">
      <div className="relative isolate overflow-hidden py-20">
        <div className=" mx-auto   bg-opacity-40 ">
          <div className=" mx-auto px-1.5 ">
            <div className="text-center  text-primary">
              <h1 className="pb-5 font-heading text-4xl md:text-7xl">
                STILL HERE & PULLING STRONG
              </h1>
              <div className="flex flex-col gap-y-2 pt-5 pb-10 text-2xl font-light md:text-3xl">
                <p>FREE PARKING - CAMPING AVAILABLE </p>
                <p className="font-bold">ADMISSION CASH ONLY</p>
                <div className="flex flex-col justify-center gap-x-2">
                  <span>ADULTS: $20.00</span>
                  <span className="hidden sm:block"> -</span>
                  <span> KIDS(6-12): $5.00</span>
                </div>
              </div>
              <StaticImage
                src="../images/shedden-tractor-pull.jpg"
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Still Here & Pulling Strong"
                className=" object-cover"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      />
    </div>
  );
}

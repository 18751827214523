import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export default function PlatinumSponsors() {
  const data = useStaticQuery(graphql`
    query platinumQuery {
      markdownRemark {
        frontmatter {
          platinum {
            title
            url
            image {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, height: 250)
              }
            }
          }
        }
      }
    }
  `);

  const { platinum } = data.markdownRemark.frontmatter;
  console.log(platinum);
  return (
    <div className="bg-white">
      <div className="mx-auto  py-5 px-4 sm:py-10 sm:px-6 lg:px-8">
        <h2 className="sponsor-title ">platinum Sponsors</h2>

        <div className="grid items-center  justify-center text-center sm:grid-cols-2 xl:grid-cols-3">
          {platinum.map((post) => (
            <div className="">
              <a href={post.url} alt={post.title}>
                <GatsbyImage
                  image={post.image.childImageSharp.gatsbyImageData}
                  alt={post.title}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

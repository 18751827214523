import React from 'react';

const GoogleMap = function () {
  return (
    <div>
      <iframe
        width="100%"
        height="500px"
        title="Shedden Truck and Tractor Pull"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11720.92601743479!2d-81.3431758!3d42.741154!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882e509d04637b8b%3A0x5f5c2b184c1aa26b!2sSouthwold%20Keystone%20Complex!5e0!3m2!1sen!2sca!4v1683561022914!5m2!1sen!2sca"
        allowfullscreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
};
export default GoogleMap;

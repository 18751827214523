import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

import HeroSection from '../components/hero';
import GoogleMap from '../components/googleMap';
import InfoSection from '../components/infoSection';
import GoldSponsors from '../components/gold';
import SilverSponsors from '../components/silver';
import EquipmentSponsors from '../components/equipment';
import Colophon from '../components/colophon';
import Camping from '../components/camping';
import PlatinumSponsors from '../components/platinum';
import BronzeSponsors from '../components/bronze';

function Index() {
  return (
    <Layout>
      <Seo title="Still Here & Pulling Strong" />

      <InfoSection />
      <Camping />
      <GoogleMap />
      <PlatinumSponsors />

      <GoldSponsors />
      <SilverSponsors />
      <BronzeSponsors />
      <EquipmentSponsors />
      <Colophon />
    </Layout>
  );
}

export default Index;
